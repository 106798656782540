html, body, #root {
  margin: 0;
  width: 100%;
  height: 100%;
}

a, a:hover {
  text-decoration: none;
  color: inherit;
}

.form-row > .ms-Grid-col {
  margin-bottom: 8px;
}

.choice-horizontal .ms-ChoiceFieldGroup-flexContainer {
  display: flex;
  flex-direction: row;
}

.choice-horizontal .ms-ChoiceField-wrapper {
  margin-right: 24px;
}

.dropdown-menu-right {
  left: auto !important;
}

.item-grid {
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(14rem, 1fr) );
  justify-items: center;
  row-gap: 24px;
  column-gap: 24px;
}

.item-card {
  width: 100%;
  height: 100%;
}

.item-card .card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  padding-top: 4px;
}

.item-card .card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.item-card .card-img-top {
  height: 160px;
  object-fit: cover;
}

@media screen and (max-width: 575px) {
  .item-card .card-body {
    padding: 0.8rem;
  }

  .item-grid {
    grid-template-columns: repeat( auto-fill, minmax(8rem, 1fr) );
    row-gap: 12px;
    column-gap: 12px;
  }

  .item-card .card-img-top {
    height: 125px;
  }
}

.cart-header-menu {
  width: 320px;
}

.cart-header-menu img {
  width: 40px;
  height: 40px;
}

.cover-img-full {
  max-width: 100%;
  height: 240px;
  margin-bottom: 16px;
  object-fit: cover;
}