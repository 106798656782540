@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

* {
  font-family: 'Kanit', sans-serif !important;
}

.san-serif, .san-serif * {
  font-family: sans-serif !important;
}

.container {
  /* margin: auto; */
  width: 100%;
  /* max-width: 1500px; */
  padding: 28px;
  height: 100%;
}

.container-low-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.web-grid-container {
  display: grid;
  grid-template-columns: 24% auto;
  column-gap: 24px;
}

.panel-inner-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 24px;
  row-gap: 24px;
  height: 100%;
}

.auto-height {
  height: auto !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0,0,0,0.7);

  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1199px) {
  .modal {
    align-items: flex-end;
  }

  .modal.modal-center-only {
    align-items: center;
  }

  .modal-content {
    max-width: 900px;
    width: 100%;
  }
}

.modal-content {
  width: 900px;
}

.dialog-content {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}

@media screen and (max-width: 1199px) {
  .modal-content {
    width: 100%;
  }
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.input-nofocus input:focus, .input-nofocus select:focus {
  outline: none;
}

.input-text input, .input-text option {
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

/*
========= HOME PAGE =========
*/

.homepage-why-grid {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 50% 50%;
}

@media screen and (min-width: 1200px) {
  .homepage-why-grid {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

@media screen and (max-width: 1399px) {
  .body-container {
    zoom: 0.85;
  }

  .container-low-padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .reset-zoom {
    zoom: 1.18;
  }
}

@media screen and (max-width: 1200px) {
  .body-container {
    zoom: 0.85;
    height: 100%;
  }
}

.no-zoom {
  /* zoom: 1 !important;  */
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 111vw !important;
  }
}

.hover-pointer:hover {
  cursor: pointer;
}

.device-status-table {
  border-collapse: collapse;
}

.device-status-table thead {
  border-bottom: 1px solid #ccc;
}

.device-status-table td, .device-status-table th {
  padding-top: 6px;
  padding-bottom: 6px;
}

.device-status-table th {
  padding-top: 0px;
}

.th-left th {
  text-align: left;
}

.text-white * {
  color: white;
}

.td-padding td {
  padding-top: 4px;
  padding-bottom: 4px;
}

.td-border-bottom td {
  border-bottom: 1px solid gray;
  padding-top: 8px;
  padding-bottom: 8px;
}

.bruno {
  filter: invert(1);
  background-color: white;
  min-height: 100%;
}

.bruno .bruno-noinvert {
  filter: invert(1);
}